.accordion {
  width: 100%;
  border: 1px solid rgba(209, 216, 234, 0.5);
  margin-bottom: 24px;
  transition: all 0.2s linear;
  &:hover {
    border-color: rgba(209, 216, 234, 0.5) !important;
  }
  &.is-open {
    border-color: $white;
    box-shadow: 0px 0px 45px rgba(21, 41, 71, 0.1);
    .accordion-header {
      &:hover {
        background-color: $white;
      }
      .h3 {
        color: $brand-color-primary;
      }
    }
    .accordion-close {
      transform: scale(1);
    }
    .accordion-open {
      transform: scale(0);
    }
  }
}
.accordion-header {
  background: transparent;
  border: 0;
  padding: 12px;
  padding-right: 48px;
  width: 100%;
  position: relative;
  text-align: left;
  @media (min-width: 768px) {
    padding: 22px 48px;
    padding-right: 96px;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: rgba(209, 216, 234, 0.5);
  }
  .h3 {
    transition: color 0.2s linear;
  }
}
.active-header {
  background-color: $white;
  border-color: transparent;
  border-bottom: 0;
}
.accordion-controls {
  position: absolute;
  z-index: 1;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  @media (min-width: 768px) {
    top: 22px;
    right: 48px;
  }
}
.accordion-control {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 24px;
  height: 24px;
  margin: -12px;
  transition: transform 0.2s linear;
}
.accordion-close {
  transform-origin: center;
  transform: scale(0);
}
.accordion-open {
  transform-style: bottom center;
  transform: scale(1);
  padding: 7px 3px;
}
.accordion-content {
  display: none;
  padding: 0 12px 12px;
  background: $white;
  @media (min-width: 768px) {
    padding: 0 48px 22px;
    padding-right: 96px;
  }
}
