// Layout
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  background-color: $grey-light;
  color: $black;
  // overflow-x: hidden; // this is causing the page to be scrollable on modal open
}

body {
  color: $black;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: white;

  .alert:empty {
    display: none;
  }
}

p {
  margin-top: 0;
}

svg:not(:root) {
  overflow: visible;
}

.main {
  min-height: 100vh;
  padding-top: 35px;
}

body:not(.panel) {
  .main {
    background-color: $bg-primary;
  }
}

.non-ab-wrapper {
  .homepage-container {
    .content {
      @media (min-width: 1200px) {
        max-width: 1180px;
      }
    }
  }
}

.ab-wrapper {
  &.ab-1 {
    .process {
      background-color: $grey;
    }
  }

  .homepage-container {
    .content {
      @media (min-width: 1420px) {
        max-width: 1320px;
      }
    }
  }
}

.content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 18px;
  width: 100%;

  &::after {
    content: "";
    clear: both;
    display: block;
  }
}

.row {
  @media screen and (min-width: 650px) {
    .column {
      &:not(:last-of-type) {
        margin-right: 40px;
      }
    }
  }
}

.not-printable {
  @media print {
    visibility: hidden;
  }
}

.mobile-only {
  @media (min-width: 769px) {
    display: none;
  }
}

.desktop-only {
  cursor: unset;
  pointer-events: none;
  user-select: all;

  @media (max-width: 768px) {
    display: none;
  }
}

.bg-light {
  background: $bg-primary;
}

.bg-white {
  background: white;
}

// .section {
//   &:nth-of-type(odd) {
//     background: $white;
//   }
// }

.homepage-container,
.container {
  #refillPrescriptionModal {
    .modal-content {
      max-width: 400px;
      max-height: 375px;
      background-color: white;
      border-radius: 5px;

      .modal-body {
        padding: 30px;
        display: flex;
        flex-direction: column;

        .refill-text {
          font-size: 18px;
          text-align: center;
          line-height: 27px;
        }

        .refill-continue-btn {
          width: 100%;
          margin-bottom: 15px;
          filter: none;
          font-size: 16px;
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
  }

  #assistanceModal {
    .modal-header {
      padding: 10px 10px 0 0;
      align-items: center;
    }

    .modal-body {
      padding-top: 0;

      h4 {
        color: $primary-color;
        margin-left: 15px;

        @media (min-width: 300px) and (max-width: 430px) {
          font-size: 20px;
        }
      }

      line-height: 18px;

      .call-us {
        margin: 10px 50px;
        color: white;

        svg {
          width: 15px;

          path {
            fill: white;
          }
        }

        @media (min-width: 300px) and (max-width: 430px) {
          margin: 10px;
        }
      }

      .assistance-text {
        p {
          margin-bottom: 0;

          @media (min-width: 300px) and (max-width: 430px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
