@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.6.2/css/bootstrap.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css");

// @import './stylesheets/reset';
@import "./assets/stylesheets/colors";
@import "./assets/stylesheets/layout";
@import "./assets/stylesheets/typography";
@import "./assets/stylesheets/accordion";
@import "./assets/stylesheets/card";
@import "./assets/stylesheets/input";
@import "./assets/stylesheets/errors";

body {
  &.modal-open {
    padding-right: 0 !important; // for fixing the padding added on Firefox
  }
}
