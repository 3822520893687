body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.text-small {
  font-size: 14px;
}
.font-semi-bold {
  font-weight: 600;
}
.text-initial {
  text-transform: initial;
}
h1.h1 {
  font-size: 28px;
  line-height: 40px;
  @media (min-width: 650px) {
    font-size: 32px;
  }
  @media (min-width: 992px) {
    font-size: 48px;
    line-height: 68px;
  }
}
h2.h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  margin-bottom: 20px;
  @media (min-width: 992px) {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 30px;
  }
}
h3.h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 0;
}
h4.h4 {
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 0;
}
p {
  font-size: 16px;
  line-height: 28px;
}
.font-weight-600 {
  font-weight: 600;
}
.text-highlighted {
  color: $brand-color-secondary !important;
}
.text-brand-primary {
  color: $brand-color-primary;
}
.content-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  font-style: normal;
  color: #262a32;
  margin-bottom: 30px;
}
.content-subheading {
  font-weight: 600;
}
.text-initial {
  text-transform: initial;
}
