$white: #ffffff;
$black: #000000;
$bunker: #262a32;

$grey: #f4f6fb;
$grey-light: #e5e5e5;
$grey-mid-light: #ebeff7;
$grey-mid: #c6d4eb;
$grey-mid-dark: #5b5b5b;
$grey-dark: #6d757f;
$grey-dark-text: #909090;

$primary-color: #0077fa;
$primary-color-dark: #0071ed;
$primary-color-light: #007aff;
$primary-color-lighter: #3393fb;

$error-color: #eb5757;
$success-color: #27ae60;

$brand-color-primary: #1b53af;
$brand-color-primary-light: #0c9ced;
$brand-color-secondary-light: #f4c6c7;
$brand-color-secondary: #ec534b;
$brand-color-secondary-dark: #d3443d;
$brand-color-variant: #d98435;
$brand-color-info: #1b53af;

$bg-primary: #f1f8fb;
$bg-secondary: #e7f3f8;
$bg-error: #faebeb;
$bg-warning: #fef9e1;

$new-bg-primary: #b3d0df;
$new-bg-primary-dark: #a9c8da;

$border-primary: #bfd9e4;
$border-error: #ec534b;
$border-warning: #a58768;

$secondary-grey: #777777;

$facebook-blue: #4268b3;
$facebook-dark-blue: #34569b;

$new-card-footer: #ebeff8;
$new-hyperlink-color: #056cb6;
