.input {
  background-color: white;
  border: 1px solid #ced5e1;
  border-radius: 6px;
  color: $black;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  line-height: 18px;
  height: 36px;
  width: 100%;
  padding: 2px 10px;

  &:hover,
  &:focus {
    border-color: $primary-color;
  }

  &::placeholder {
    color: $grey-dark;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 1;
  }

  &::-moz-placeholder {
    color: $grey-dark;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 1;
  }

  &::-moz-placeholder {
    line-height: 24px;
  }

  &:not([type="date"]) {
    appearance: none;

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  &[disabled] {
    color: #777e8a;
    background-color: #f7f9fd;
    background-blend-mode: multiply;
  }

  &-select {
    appearance: none;
    background: url("../images/session/select-bg.png") center right no-repeat;
    background-size: contain;
    cursor: pointer;
    font-size: 14px;
    height: 42px;
    width: 100%;

    @media (max-width: 575px) {
      background-size: cover;
    }

    &.gender {
      background-size: auto !important;
    }

    &.bg-cover {
      background-size: cover;
    }
  }

  &.lg {
    height: 42px;

    &::-moz-placeholder {
      line-height: 38px;
    }
  }

  &-error,
  &__error {
    color: $error-color;
    display: block;
    font-size: 14px;
  }

  &-group.row {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.show-password-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;

  svg {
    width: 20px;
  }

  span,
  svg {
    pointer-events: none;
  }
}

.field_with_errors {
  width: 100%;
}

.with-icon {
  max-width: calc(100% - 41px) !important;
  border-left: 0;
  border-radius: 0 3px 3px 0;
}

.input__icon {
  padding-left: 40px;
  background-position-y: center;
  background-position-x: 15px;
  background-repeat: no-repeat;
  background-size: 12px;
  // transition: border 0.1s linear;

  &.select__icon {
    padding-left: 0;

    .input {
      padding-left: 40px;
    }
  }

  &.email {
    background-image: url("../images/session/mail.png");
  }

  &.password {
    background-image: url("../images/session/key.png");
  }

  &.user {
    background-image: url("../images/session/user.png");
  }

  &.date,
  &.date .input-select {
    background-image: url("../images/session/date.png");
    background-position: 15px center, right center;
    background-repeat: no-repeat, no-repeat;
    background-size: 12px, cover;
  }

  &.gender {
    background-image: url("../images/session/gender.png");
  }

  &.gender .input-select {
    background-image: url("../images/session/gender.png"),
      url("../images/session/select-bg.png");
    background-position: 15px center, right center;
    background-repeat: no-repeat, no-repeat;
    background-size: 12px, cover;
  }

  &.phone {
    background-image: url("../images/session/phone.png");
  }

  &.fax {
    background-image: url("../images/session/fax.png");
  }

  &.address {
    background-image: url("../images/session/nav.png");
  }

  &.city {
    background-image: url("../images/session/city.png");
  }

  &.zip {
    background-image: url("../images/session/zip.png");
  }

  &.state {
    background-image: url("../images/session/state.png");
  }

  &.country {
    background-image: url("../images/session/country.png");
  }

  &.search {
    background-image: url("../images/session/search.png");
  }

  &.pharmacy {
    background-image: url("../images/session/pharmacy.png");
  }

  &.card-ico {
    background-image: url("../images/session/card-ico.png");
  }
}

.input-label {
  color: rgba(0, 0, 0, 0.5);
  display: block;
  font-size: 12px;
  line-height: 21px;
  margin-bottom: 0.25em;

  @media (max-width: 1024px) {
    font-size: 10px;
  }

  &.dropdown-label {
    margin-bottom: 0;
  }
}

.dropdown-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 220px;
  height: 95px;
  padding: 15px;
  border: 3px solid $border-primary;
  border-radius: 5px;
  font-weight: 700;
  color: $black;
  font-size: 14px;
  transition: 0.1s ease-in-out;
  position: relative;

  &::after {
    content: "";
    width: 15px;
    height: 8px;
    // background-image: url('controls/caret-down.svg');
    top: 50%;
    right: 10px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    transition: 0.2s ease-in-out;
  }

  &:hover {
    background: white;
    cursor: pointer;
  }

  &.active {
    border-color: $brand-color-secondary;
    background: white;

    &::after {
      transform: rotate(180deg);
    }
  }

  span {
    pointer-events: none;
  }

  .select {
    pointer-events: none;
  }

  .input-select {
    font-size: 13px;
    border: 0;
    padding: 0;
    height: 30px;
    background-image: none;
    pointer-events: none;
  }

  button.generic {
    border-left: 0;
    text-align: left;
  }

  .touched + .select-dropdown {
    margin: 0 -15px;
    font-weight: 400;
    border-radius: 3px;
    font-size: 14px;

    @media (max-width: 1024px) {
      left: 5px;
    }

    &::before {
      border: 0;
    }
  }
}

.custom-checkbox {
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;

    &:focus {
      + .labelbefore {
        outline: 2px solid $brand-color-secondary;
        outline-offset: 3px;
      }
    }
  }
}

.checkbox-label {
  cursor: pointer;
  position: relative;
  padding-left: 32px;

  &::before {
    content: "";
    background-color: $white;
    background-position: top center;
    background-size: 16px;
    background-repeat: no-repeat;
    display: block;
    height: 22px;
    width: 22px;
    border: 1px solid $brand-color-secondary;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 3px;
  }

  &.input-label {
    font-size: 14px;
  }
}

input[type="checkbox"]:checked + .checkbox-label::before,
.checked.checkbox-label::before {
  background-color: $brand-color-secondary;
  background-image: url("../images/session/checkbox.png");
}

.field-with-error .input {
  border-color: $error-color;
}

select.input.input-select {
  background-size: 150%;
  background-color: white;
  text-transform: capitalize;

  &.form-control,
  &.bg-contain {
    background-size: contain;
  }

  &.bg-cover {
    background-size: cover;
    padding-right: 40px;
  }
}

.field {
  .input-group {
    &:hover,
    &:focus-within {
      .input,
      .input__icon {
        transition: 0.2s ease-in-out;
        border-color: $brand-color-primary !important;
      }
    }
  }
}
