@mixin aum-card {
  background-color: $white;
  border: 0;
  border-radius: 16px;
  box-shadow: 0px 0px 45px rgba(21, 41, 71, 0.1);
  height: 418px;
  margin-bottom: 61px;
  padding: 60px 24px 24px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  @media (min-width: 768px) {
    width: 30%;
    &:not(:last-of-type) {
      margin-right: 40px;
    }
  }
  p {
    color: $grey-mid-dark;
    font-size: 14px;
    line-height: 21px;
  }
  .svg {
    margin-bottom: 40px;
    position: relative;
    height: 118px;
    width: 100%;
    svg {
      color: $brand-color-secondary;
    }
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 12px;
    span {
      color: $brand-color-secondary;
      white-space: nowrap;
    }
    em {
      font-style: normal;
      white-space: nowrap;
    }
  }
  &-telemedicine {
    background: #e6f1f9;
    border-radius: 5px;
    width: 100%;
    padding: 25px;
    .card__title {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      h4 {
        margin-left: 20px;
        padding-right: 20px;
        color: $brand-color-primary;
        font-weight: 600;
      }
      svg,
      img {
        width: 65px;
      }
    }
    .card__body {
      p {
        font-size: 15px;
        color: #777;
        strong {
          color: #262a32;
          font-weight: 600;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  &-info {
    padding: 35px 30px;
    background: white;
    border-radius: 5px;
    &-bordered {
      border: 1px solid $border-primary;
      border-radius: 6px;
      padding: 20px;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      margin-bottom: 10px;
      h6 {
        font-weight: 600;
      }
    }
    &__body {
      ul {
        list-style: none;
        padding-left: 0;
      }
      li:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }
  }
}

// .card:not(.testimonial-card) {
//   @include aum-card;
// }

.testimonials-wrapper {
  .card.testimonial-card {
    @include aum-card;
    svg {
      position: unset;
      top: unset;
      left: unset;
      transform: unset;
    }
  }
}

.card-number {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $brand-color-primary;
  color: $white;
  font-size: 24px;
  font-weight: 700;
}
