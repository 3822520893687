#error_explanation {
  margin-bottom: 20px;

  ul {
    padding-left: 1em;
  }
}

.field_with_errors,
.field-with-error {
  .input__icon {
    border-color: $brand-color-secondary !important;
  }

  .input {
    border-color: $brand-color-secondary !important;
    max-width: 100%;

    &.input-field-border {
      border-color: #ced5e1 !important;
    }
  }
}

.with-icon {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &[required],
  &.leftBorder {
    border-left: 0 !important;

    & ~ .input__icon {
      border-left: 3px solid #cf2a2a !important;
    }
  }

  &[disabled] ~ .input__icon {
    background-color: #f7f9fd;
  }
}

.field_with_errors ~ .input__icon,
.with-icon ~ .input__icon {
  order: -1;
  border: 1px solid #ced5e1;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-right: 0;

  &.input-field-border {
    border-color: #ced5e1;
  }
}

.field_with_errors ~ .input__icon {
  border-color: $error-color;
}

.field_with_errors ~ .input-group .input {
  max-width: 100% !important;
}
